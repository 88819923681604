import React from 'react';
import { useRouter } from 'next/router';
import {
  AUFlagIcon,
  NZFlagIcon,
  USFlagIcon,
} from '../../../core';
import { Button } from '@ui/components/core/button';
import env from '@ui/env';
import cn from '@ui/utils/cn';
import { Separator } from '@ui/components/core/separator';

export type CountrySelectorProps = {
  className?: string;
}

const CountrySelector:React.FC<CountrySelectorProps> = ({className}) => {
  const router = useRouter();

  return (
    <div className={cn('flex flex-col md:flex-row items-center gap-4',className)}>
      <p className='text-sm uppercase'>
        You&apos;re shopping in:
      </p>
      <div className='flex items-center gap-4'>
        <Button
          variant="unstyled"
          onClick={() => router.push('https://nz.hellomolly.com/')}
          data-selected={env.NEXT_PUBLIC_REGION === 'NZ' || undefined}
          className="gap-4 opacity-30 hover:opacity-100 data-[selected]:opacity-100 no-underline"
        >
          <NZFlagIcon height={30} width={30} />
              NZ
        </Button>
        <Separator orientation="vertical" className='bg-black h-[18.5px] w-[0.6px] my-[0.125rem]'/>
        <Button
          variant="unstyled"
          onClick={() => router.push('https://hellomolly.com.au/')}
          data-selected={env.NEXT_PUBLIC_REGION === 'AU' || undefined}
          className="gap-4 opacity-30 hover:opacity-100 data-[selected]:opacity-100 no-underline"
        >
          <AUFlagIcon height={30} width={30} />
            AU
        </Button>
        <Separator orientation="vertical" className='bg-black h-[18.5px] w-[0.6px] my-[0.125rem]'/> 
        <Button
          variant="unstyled"
          onClick={() => router.push('https://www.hellomolly.com/')}
          data-selected={env.NEXT_PUBLIC_REGION === 'US' || undefined}
          className="gap-4 opacity-30 hover:opacity-100 data-[selected]:opacity-100 no-underline"
        >
          <USFlagIcon height={30} width={30} />
            USA
        </Button>
      </div>
    </div>
  );
};

export default CountrySelector;
