import React from 'react';
import {
  GiftCardIcon,
  Logo,
} from '@ui/components/core';
import { SubTitle } from '../../typography';
import CountrySelector from '../../selectors/CountrySelector/CountrySelector';
import { FooterMenuRoutes } from '../../../../types/routing/FooterMenuRoutingTable.type';
import Image from '@ui/components/core/image';
import { Separator } from '@ui/components/core/separator';
import { Container } from '@ui/components/core/container';
import Link from 'next/link';

type FooterMenuProps = {
  menuHeadingLabels: Array<string>;
  menuLabels: { [key: string]: Array<string> };
};

function setLocaleYear(): string {
  return new Intl.DateTimeFormat().formatToParts(new Date())?.find(({type}) => type === "year")?.value ?? "";
};

const FooterMenu = ({ menuHeadingLabels, menuLabels }: FooterMenuProps): React.ReactElement => {

  const socialLinks = (
    <>
      <a href="https://tiktok.com/@hellomolly/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-tiktok.png?v=1700115989"
          alt="Hello Molly on Tiktok"
          width="224"
          height="240"
        />
      </a>
      <a href="https://instagram.com/hellomolly/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-instagram.png?v=1700115989"
          alt="Hello Molly on Instagram"
          width="241"
          height="240"
        />
      </a>
      <a href="https://www.facebook.com/hellomolly/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-facebook.png?v=1700115988"
          alt="Hello Molly on Facebook"
          width="127"
          height="240"
        />
      </a>
      <a href="https://www.pinterest.com/hellomollyfshn/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-pinterest.png?v=1700115988"
          alt="Hello Molly on Pinterest"
          width="207"
          height="253"
        />
      </a>
      <a href="https://www.youtube.com/user/HelloMollyFashion/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-youtube.png?v=1700115987"
          alt="Hello Molly on Youtube"
          width="331"
          height="240"
        />
      </a>
      <a href="https://www.linkedin.com/company/hello-molly/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-linkedin.png?v=1700115988"
          alt="Hello Molly on Linked In"
          width="254"
          height="240"
        />
      </a>
      <a href="https://twitter.com/LoveHellomolly/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-twitter.png?v=1700115987"
          alt="Hello Molly on Twitter"
          width="241"
          height="220"
        />
      </a>
      <a href="https://threads.net/hellomolly/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-threads.png?v=1700115987"
          alt="Hello Molly on Threads"
          width="211"
          height="244"
        />
      </a>
    </>
  );

  return (
    <>
      <div className="hidden md:block">
        <div className="bg-pink-3">
          <Container className='py-12'>
            <div className='flex md:flex-col lg:flex-row items-start justify-between'>
              <div className='flex gap-12'>
                {menuHeadingLabels.map((menuHeadingLabel) => (
                  <div key={`${menuHeadingLabel}-footer-menu-heading`} className='flex flex-col gap-3 w-[200px]'>
                    <h3 className='text-sm font-bold'>
                      {menuHeadingLabel}
                    </h3>
                    {menuLabels[menuHeadingLabel].map((menuLabel) => (
                      <div key={`${menuLabel}-footer-menu-pagelink`}>
                        {menuLabel === 'Gift Cards' ? (
                          <div className="flex items-center gap-[0.625rem] py-4">
                            <GiftCardIcon height={30} width={30} />
                            <Link
                              href="/products/gift-card/"
                              className="text-sm next-link tracking-[0em] font-bold"
                            >
                              {menuLabel}
                            </Link>
                          </div>
                        ) : (
                          <div>
                            <Link
                              href={FooterMenuRoutes[menuLabel.toUpperCase() as keyof typeof FooterMenuRoutes] || '#'}
                              className="text-sm next-link tracking-[0em]"
                            >
                              {menuLabel}
                            </Link>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className='flex flex-col items-center justify-center gap-4 md:mt-4 lg:mt-0'>
                <CountrySelector className='gap-4 items-center justify-center' />
                <div className="flex gap-8 py-6 items-center group">
                  {socialLinks}
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="bg-brand-lightpink2 border-t-[0.6px] border-black">
          <Container>
            <div className="flex relative items-end justify-center pt-4 pb-2">
              <div className="flex items-end absolute left-0">
                <Logo height={35} width={100} className="mr-8" />
                <Link
                  href="/pages/terms-and-conditions"
                  className="text-sm next-link tracking-[0em]"
                >Terms & Conditions</Link>
                <span className="mx-4 text-sm">|</span>
                <Link
                  href="/pages/privacy-policy/"
                  className="text-sm next-link tracking-[0em]"
                >Privacy Policy</Link>
              </div>
              <div className="text-[12px] tracking-[-0.06px]">© {setLocaleYear()} Hello Molly. All Rights Reserved</div>
            </div>
          </Container>
        </div>
      </div>
      <div className="md:hidden">
        <div className="bg-brand-lightpink">
          <Container className="px-4 py-8">
            <div className="grid grid-cols-2 gap-9">
              {menuHeadingLabels.map((menuHeadingLabel) => (
                <div key={menuHeadingLabel}>
                  <SubTitle className="font-bold text-sm mb-[22px]">{menuHeadingLabel}</SubTitle>
                  {menuLabels[menuHeadingLabel]
                    .filter((menuLabel) => menuLabel !== 'Gift Cards')
                    .map((menuLabel) => (
                      <div key={menuLabel} className="mb-[18px] last-of-type:mb-0">
                        <Link
                          href={FooterMenuRoutes[menuLabel.toUpperCase() as keyof typeof FooterMenuRoutes] || '#'}
                          className='next-link text-sm'
                        >{menuLabel}</Link>
                      </div>
                    ))}
                </div>
              ))}
              <div>
                <div className='flex items-center gap-2'>
                  <GiftCardIcon height={30} width={30} />
                  <Link
                    href="/products/gift-card/"
                    className='next-link font-bold text-sm'
                  >Gift Cards</Link>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div
          className='border-t-[0.6px] border-b-[0.6px] border-black'
        >
          <div className="bg-brand-lightpink">
            <CountrySelector className='px-6 py-6' />
          </div>
        </div>
        <div className="bg-brand-lightpink">
          <Container className="py-6">
            <div className='group flex justify-center items-center gap-4'>{socialLinks}</div>
          </Container>
        </div>
        <div className="bg-brand-lighestpink border-t-[0.6px] border-black">
          <Container className='pb-4'>
            <div className='flex flex-col items-center justify-center gap-4'>
              <Logo height={45} width={100} />
              <div className='group flex items-center justify-center mt-auto gap-[0.625rem]'>
                <Link href="/pages/terms-and-conditions/" className='next-link'>Terms & Conditions</Link>
                <Separator orientation="vertical" className='bg-black w-[0.6px] h-[21px]'/>
                <Link href="/pages/privacy-policy" className='next-link'>Privacy Policy</Link>
              </div>
              <p className='text-xs'>© {setLocaleYear()} Hello Molly. All Rights Reserved</p>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default FooterMenu;
